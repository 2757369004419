export default [{
    title: '订单中心',
    list: [{
        name: '商品订单',
        linkUrl: '/order/index'
    },
    {
        name: '卡券订单',
        linkUrl: '/user/exchangeinfo'
    },
    ]
},
{
    title: '个人中心',
    list: [{
        name: '我的个人中心',
        linkUrl: '/user/Index'
    },
    {
        name: '收货地址',
        linkUrl: '/user/address'
    }
    ]
},
{
    title: '账户管理',
    list: [{
        name: '个人信息',
        linkUrl: '/user/info'
    },
    {
        name: '账号安全',
        linkUrl: '/user/account'
    },
    {
        name: '流水记录',
        linkUrl: '/user/balancehistory'
    },
    ]
},
{
    title: '淘豆中心',
    list:[
    //     {
    //     name: '优惠券交易',
    //     linkUrl: '/user/exchange'
    // },
    {
        name: '充值云淘豆',
        linkUrl: '/user/recharge'
    }]
},
{
    title: '采购管家',
    list:[{
        name: '我要采购',
        linkUrl: '/user/purchase'
    },
    {
        name: '我要留言',
        linkUrl: '/user/message'
    }]
}
]